import * as statusMapper from "@/service/error_request_mapper.js";

export class LegalRepresentativeStatusService {
  constructor(api) {
    this.api = api;
  }

  async getAll() {
    return this.api
      .getAll()
      .then((data) => {
        data.sort(function(a, b) {
          return a.label.localeCompare(b.label);
        });
        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  async getById(statusId) {
    return this.api
      .getById(statusId)

      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.add404(statusId);
        converter.convert(error);
      });
  }

  async create(status) {
    return this.api.create(status).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(status.id);
      converter.add409AlreadyExists(status.label);
      converter.convert(error);
    });
  }

  async update(status) {
    return this.api.update(status).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(status.id);
      converter.convert(error);
    });
  }

  async delete(statusId) {
    return this.api.delete(statusId).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409Integrity(statusId);
      converter.convert(error);
    });
  }
}
